@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.langselector {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 24px;

  @include bp(tablet) {
    position: absolute;
    top: 24px;
    right: 0;
  }

  @include bp(desktop-large) {
    top: 40px;
  }

  @include bp(desktop-wide) {
    top: 24px;
  }
}

.lang {
  input {
    display: none;
  }

  // a {
  color: $color__darkblue-2;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1;
  text-transform: uppercase;
  display: flex;
  height: 32px;
  align-items: center;
  box-shadow: 4px 4px 26px 0px #00000012;
  padding: 0 8px;
  background-color: #ffffff;

  @include bp(desktop-large) {
    height: 24px;
  }

  // }

  &.active {
    // a {
    color: #ffffff;
    background-color: $color__darkblue-2;
    // }
  }
}
