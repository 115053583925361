@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.about {
  color: $color__black--lighter;
  padding-top: 96px;
  padding-bottom: 72px;
  background-color: #ffffff;

  h2 {
    font-size: 52px;
    line-height: 56px;
    margin-top: 0;
    margin-bottom: 32px;
  }
}

.about__desc {
  p:nth-child(1) {
    font-size: 20px;
    /*line-height: 32px;*/
  }

  p:nth-child(2) {
    font-size: 20px;
  }

  strong {
    font-weight: 500;
  }
}

.about__date {
  color: $color__blue;
  font-size: 44px;
  font-weight: 500;
  margin-bottom: 24px;
}

.about__timetableTitle {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 24px;

  span {
    font-weight: 300;
  }
}

.about__timetable {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      @include bp(0 desktop-large) {
        justify-content: space-between;
      }

      strong {
        color: $color__green--light;
        font-size: 44px;
        font-weight: 500;
        margin-right: 76px;

        span {
          font-size: 14px;
        }
      }

      span {
        font-size: 20px;
        @include bp(0 desktop-large) {
          width: 215px;
        }
        @include bp(desktop-wide) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
}
