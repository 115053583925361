@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
@keyframes updown {
  0% {
    transform: translateY(2px);
  }

  50% {
    transform: translateY(-2px);
  }

  100% {
    transform: translateY(2px);
  }
}

.speakers {
  background: $color__darkblue;
  color: #fff;
  font-family: $font-family-main;
  padding: 64px 0 0;
  position: relative;
  overflow: visible;
  z-index: 5;

  @include bp(mobile) {
    padding: 64px 0 14px;
  }

  @include bp(tablet) {
    padding: 72px 0;
  }

  // @include bp(desktop-large) {
  //   padding: 80px 0;
  //   // height: 668px;
  // }

  // @include bp(desktop-wide) {
  //   padding: 96px 0;
  //   // height: 768px;
  // }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 1;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 5;
  }

  &__wrap {
    width: 100%;
  }

  &__title {
    width: 100%;
    text-align: center;
    color: #fff;
    font-weight: 300;
    font-size: 36px;
    line-height: 52px;
    margin: 0 0 15px 0;

    @include bp(tablet) {
      font-size: 42px;
      margin: 0 0 24px 0;
    }

    @include bp(desktop-large) {
      font-size: 48px;
      line-height: 56px;
      margin: 0 0 40px 0;
    }

    @include bp(desktop-wide) {
      font-size: 56px;
      line-height: 56px;
      // margin: 0 0 56px 0;
    }
  }

  &__list {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @include bp(tablet) {
      justify-content: flex-start;
    }

    @include bp(desktop-large) {
      margin-left: -76px;
      margin-right: -76px;
    }

    @include bp(desktop-wide) {
      margin-left: -72px;
      margin-right: -72px;
    }
  }
}

.speakersItem {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
  position: relative;
  transition: all 0.4s;

  &__wrap {
    position: relative;
    z-index: 1;
    padding: 0 0 30px 0;
    width: 100%;

    @include bp(mobile) {
      padding: 0 0 58px 0;
    }

    @include bp(desktop-large) {
      padding: 0 0 20px 0;
    }
  }

  &__picture {
    width: 176px;
    height: 176px;
    margin: 0 0 32px 0;
    position: relative;
    mask-image: url("/images/mask.svg");
    mask-size: 176px;
    mask-position: 50%;
    mask-repeat: no-repeat;
    transition: all 0.8s;
    display: inline-flex;
    max-width: 100%;

    @include bp(tablet) {
      margin: 0 0 32px 0;
      width: 176px;
      height: 176px;
      mask-size: 176px;
    }

    @include bp(desktop-wide) {
      width: 240px;
      height: 240px;
      mask-size: 240px;
      margin: 0 0 32px 0;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      transition: all 0.4s;
      background: linear-gradient(225deg,
          rgba(255, 255, 255, 0) 50%,
          #07e484 100%);
    }
  }

  &__name {
    font-size: 22px;
    font-weight: 400;
    margin: 0 0 8px 0;
    color: #fff;

    @include bp(tablet) {
      font-size: 20px;
      margin: 0 0 8px 0;
    }

    @include bp(desktop-large) {
      font-size: 22px;
    }

    @include bp(desktop-wide) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  &__position {
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 16px 0;
    // white-space: nowrap;

    @include bp(tablet) {
      font-size: 14px;
      margin: 0 0 16px 0;
    }

    @include bp(desktop-large) {
      margin: 0 0 8px 0;
      // white-space: nowrap;
    }

    @include bp(desktop-wide) {
      margin: 0 0 16px 0;
    }

    br {
      display: none;

      @include bp(tablet) {
        display: inline-block;
      }
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    max-width: 320px;
    padding: 0 10px;
    margin: 0 auto;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 16px;

    @include bp(tablet) {
      font-size: 16px;
      line-height: 24px;
      max-width: 384px;
    }

    // @include bp(desktop-wide) {
    //   margin-bottom: 32px;
    // }
  }

  &__more {
    display: none;

    display: block;
    width: 24px;
    height: 12px;
    background-image: url("/images/arrow-green.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    opacity: 1;
    margin: 0 auto;
    transform: rotate(0);
    transition: transform 0.3s;

    @include bp(mobile desktop) {
      bottom: 40px;
      width: 48px;
      height: 24px;
    }

    @include bp(desktop) {
      bottom: 40px;
      animation: updown 2s linear 0s infinite forwards;
    }

    @include bp(desktop-large) {
      bottom: 0;
    }
  }
}

// .speakersItem__text {
//   display: ;
// }

// .speakersItem__text_active {
//   display: block;
//   -webkit-line-clamp: 100;
//   overflow: visible;
// }

// .speakersItem__more_turned {
//   transform: rotate(-180deg);

//   @include bp(desktop) {
//     animation: none;
//     margin-bottom: 10px;
//   }
// }

.speakersItem__picture_label {
  position: relative;

  span {
    text-transform: uppercase;
    color: #ffffff;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #11dd7c;
    padding: 8px;
    z-index: 2;
  }
}

.speakersItem_blured {
  .speakersItem__picture::before {
    display: none;
  }

  img {
    filter: blur(4px);
  }

  .speakersItem__name,
  .speakersItem__position,
  .speakersItem__text,
  .speakersItem__more {
    filter: blur(10px);
    user-select: none;
  }
}

.speakersItem_short {

  .speakersItem__name,
  .speakersItem__position {
    @include bp(tablet) {
      max-width: 152px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }
  }

  .speakersItem__picture {
    @include bp(tablet) {
      width: 144px;
      height: 144px;
      mask-size: 144px;
    }
  }

  .speakersItem__name {
    font-size: 20px;

    @include bp(mobile tablet) {
      font-size: 40px;
    }
  }
}

.speakers__slider {

  /* hack for hide overflow speakers */
  &:before,
  &:after {
    content: "";
    background: #043348;
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 1;
  }

  &:before {
    left: -100%;
  }

  &:after {
    right: -100%;
  }

  :global {
    .swiper-wrapper {
      z-index: 0;
    }

    .swiper-button-next {
      @include bp(tablet) {
        right: -40px;
      }
    }

    .swiper-button-prev {
      @include bp(tablet) {
        left: -40px;
      }
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 46px;
    }
  }
}

.speakersItem__wrap_fake {
  display: none;

  @include bp(desktop) {
    display: block;
  }
}

.speakersItem_active {
  .speakersItem__wrap:not(.speakersItem__wrap_fake) {
    @include bp(desktop) {
      background-color: $color__darkblue-light;
      position: absolute;
      top: -32px;
      left: 0;
      right: 0;
      padding: 32px 12px;
      box-shadow: 0px 8px 26px rgba(0, 0, 0, 0.3);
      z-index: 2;
    }

    @include bp(desktop-wide) {
      padding: 32px 24px;
    }

    .speakersItem__text {
      display: block;
      -webkit-line-clamp: 100;
      overflow: visible;
    }

    .speakersItem__more {
      display: none;
      // transform: rotate(-180deg);

      // @include bp(desktop) {
      //   animation: none;
      //   margin-bottom: 10px;
      // }
    }
  }
}