@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.col {
  /* Why paddings https://zellwk.com/blog/responsive-grid-system/ */
  /*
    TODO: reseach for subgrid
    calc(100vw - var($xcontainer__padding, 12px) * 2)
    */
  $width-base: Min(#{$max-container-width}, 100%);
  /* min cause we don't need more cols then we have in grid */
  $width: calc((#{$width-base} / #{$cols}) * Min(#{$cols}, var(--cols-item)));
  $width-sm: calc(
    (#{$width-base} / #{$cols-sm}) * Min(#{$cols-sm}, var(--cols-item-sm))
  );
  $width-md: calc(
    (#{$width-base} / #{$cols-md}) * Min(#{$cols-md}, var(--cols-item-md))
  );
  $width-lg: calc(
    (#{$width-base} / #{$cols-lg}) * Min(#{$cols-lg}, var(--cols-item-lg))
  );
  $width-xl: calc(
    (#{$width-base} / #{$cols-xl}) * Min(#{$cols-xl}, var(--cols-item-xl))
  );

  // /* for nesting width without extra props */
  // $item-width: var($width);
  // $item-width-sm: var($width-sm, var($item-width));
  // $item-width-md: var($width-md, var($item-width-sm));
  // $item-width-lg: var($width-lg, var($item-width-md));
  // $item-width-xl: var($width-xl, var($item-width-lg));
  flex: 0 0 $width;
  width: 100%;
  max-width: $width;
  padding: calc(#{$xgrid__gutter} / 2);
  @include bp(tablet-small) {
    /* calc(100vw - var($xcontainer__padding-sm, 64px) * 2) */
    $width-base: Min(#{$max-container-width}, 100%);
    padding: calc(#{$xgrid__gutter-sm} / 2);
    &[style*="--cols-item-sm"] {
      flex: 0 0 $width-sm;
      max-width: $width-sm;
    }
  }
  @include bp(desktop) {
    /* calc(100vw - var($xcontainer__padding-md, 96px) * 2) */
    $width-base: Min(#{$max-container-width}, 100%);
    padding: calc(#{$xgrid__gutter-md} / 2);
    &[style*="--cols-item-md"] {
      flex: 0 0 $width-md;
      max-width: $width-md;
    }
  }
  @include bp(desktop-large) {
    /* calc(100vw - var($xcontainer__padding-lg, 96px) * 2) */
    $width-base: Min(#{$max-container-width}, 100%);
    padding: calc(#{$xgrid__gutter-lg} / 2);
    &[style*="--cols-item-lg"] {
      flex: 0 0 $width-lg;
      max-width: $width-lg;
    }
  }
  @include bp(desktop-wide) {
    /* calc(100vw - var($xcontainer__padding-xl, 120px) * 2) */
    $width-base: Min(#{$max-container-width}, 100%);
    padding: calc(#{$xgrid__gutter-xl} / 2);
    &[style*="--cols-item-xl"] {
      flex: 0 0 $width-xl;
      max-width: $width-xl;
    }
  }
  &.col--noGutters {
    padding: 0;
  }
}
