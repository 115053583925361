@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.row {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  margin: calc(#{$xgrid__gutter} / -2);
  @include bp(tablet-small) {
    margin: calc(#{$xgrid__gutter-sm} / -2);
  }
  @include bp(desktop) {
    margin: calc(#{$xgrid__gutter-md} / -2);
  }
  @include bp(desktop-large) {
    margin: calc(#{$xgrid__gutter-lg} / -2);
  }
  @include bp(desktop-wide) {
    margin: calc(#{$xgrid__gutter-xl} / -2);
  }
  & + .xRow {
    margin-top: $xgrid__gutter;
  }

  /* Remove the negative margin from default .row, then the horizontal padding */
  /* from all immediate children columns (to prevent runaway style inheritance). */
  &.no-gutters {
    margin: 0;
    & > .col,
    & > [class*="col-"] {
      padding: 0;
    }
  }
}
