@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.intro {
  background-color: #ffffff;
  position: relative;
  color: $color__darkblue-2;

  @include bp(0 desktop-large) {
    display: flex;
    flex-direction: column-reverse;
  }

  &.intro_noheader {
    padding-top: 40px;

    @include bp(desktop-large) {
      padding-top: 50px;
    }
  }

  h1 {
    color: inherit;
    font-weight: 700;
    line-height: 1.2;
    font-size: 26px;

    @include bp(tablet) {
      font-size: 32px;
    }

    @include bp(desktop-large) {
      font-size: 36px;
      margin-bottom: 16px;
    }

    @include bp(desktop-wide) {
      font-size: 44px;
      margin-bottom: 24px;
    }
  }

  p {
    font-size: 14px;
    line-height: 24px;
    color: $color__black--lighter;
    margin-top: 0;
    margin-bottom: 24px;

    @include bp(tablet) {
      font-size: 16px;
    }

    @include bp(desktop-large) {
      margin-bottom: 16px;
    }

    @include bp(desktop-wide) {
      margin-bottom: 24px;
    }
  }

  .intro__lang_noheader {
    @include bp(0 desktop-wide) {
      top: 0;
    }
  }
}

.intro__breadcrumbs {
  padding-top: 16px;
  padding-bottom: 16px;

  @include bp(tablet) {
    padding-top: 30px;
  }

  @include bp(desktop-large) {
    padding-top: 35px;
  }

  @include bp(desktop-wide) {
    padding-top: 20px;
  }
}

.intro__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  padding-top: 0;
  padding-bottom: 0;

  @include bp(0 desktop-large) {
    padding-bottom: 40px;
    order: 1;
  }

  @include bp(desktop-wide) {
    padding-right: 72px;
    padding-bottom: 0;
  }
}

.intro__text {
  p {
    @include bp(0 tablet) {
      display: none;
    }
  }

  @include bp(desktop-large) {
    width: 50%;
    padding-right: 72px;
    padding-bottom: 64px;
  }

  @include bp(desktop-wide) {
    padding-bottom: 0;
  }
}

.intro__date {
  font-size: 20px;
  font-weight: 600;
  color: $color__blue;
  letter-spacing: 0.84px;
  text-transform: uppercase;
  margin-bottom: 16px;

  span {
    font-weight: 500;
    font-size: 12px;
  }

  @include bp(tablet) {
    font-size: 24px;
  }

  @include bp(desktop-large) {
    font-size: 26px;
    margin-bottom: 24px;
  }

  @include bp(desktop-wide) {
    font-size: 28px;
  }
}

.intro__location {
  a {
    @include textIcon();
    @include textIconLocation();
  }

  span {
    @include textIcon();
    @include textIconPlay();
  }
}

.intro__img {
  object-fit: cover;
  object-position: center;
  z-index: 1;

  height: 240px;

  @include bp(tablet) {
    height: 296px;
  }

  @include bp(desktop-large) {
    height: 100%;
    top: 0;
    left: 50%;
    width: 50%;
    position: absolute;
    object-position: center;
  }

  &.intro__img_withmobile {
    @include bp(0 desktop-wide) {
      display: none;
    }
  }
}

.intro__langbuttons {
  @include bp(0 desktop-large) {
    display: none;
  }

  display: flex;
  padding-top: 40px;

  input {
    display: none;
  }

  .intro__lang {
    color: $color__darkblue-2;
    font-size: 14px;
    line-height: 32px;
    text-transform: uppercase;
    padding: 0 8px;
    background-color: #ffffff;
    cursor: pointer;
  }

  input:checked + .intro__lang,
  .intro__lang_active {
    color: #ffffff;
    background-color: $color__darkblue-2;

    cursor: default;
  }
}

.intro__img.intro__img_mobile {
  display: none;
  object-position: center;

  @include bp(0 desktop-wide) {
    display: block;
  }
}

.intro__content {
  @include bp(desktop-wide) {
    padding-top: 15px;
    padding-bottom: 88px;
  }
}

.intro__label {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background-color: $color__blue;
  padding: 4px 16px;

  @include bp(desktop-wide) {
    font-size: 16px;
  }
}
